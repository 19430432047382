<template>
  <div class="modal fade" ref="mdlProrrogaFactura" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Promesa de pago
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cerrar()"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            Selecciona la nueva fecha en que el cliente promete pagar su servicio:
          </p>
          <input 
            ref="fechaParaPago"
            type="date" 
            v-model="fecha_para_pago"
            class="form-control"
            pattern="\d{4}-\d{2}-\d{2}"
            :min="hoy"
            :max="fecha_maxima_seleccionable"
            @change="validarFechaParaPago(); actualizarProrroga()"
          />
          <br>
          <label for="">Motivo de la promesa de pago</label>
          <textarea
            ref="motivo"
            class="form-control"
            placeholder="Describe el motivo de la promesa de pago"
            rows="5"
            v-model="prorroga.motivo"
          ></textarea>

          <br>
          <div class="text-right">
            <button
              class="btn btn-danger me-1"
              data-bs-dismiss="modal"
              @click="cerrar()"
            >
              <i class="mdi mdi-cancel"></i>
              Cancelar
            </button>
            <button
              class="btn btn-success"
              @click="guardarProrroga()"
              :disabled="bandera_spinner || fecha_para_pago == fecha_para_pago_base"
            >
              <i 
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal';

import SistemaSrv from '@/services/SistemaSrv.js'
import FacturaSrv from '@/services/FacturaSrv.js'

import moment from 'moment'

export default {
  name: 'MdlProrrogaFactura',

  data() {
    return {
      id_factura: null,
      prorroga:{
        dias: 1,
        motivo: ''
      },
      hoy: null,
      fecha_para_pago_base: null,
      fecha_para_pago: null,
      fecha_vencimiento: null,
      fecha_maxima_seleccionable: null,
      bandera_spinner: false,
      ajustes: {
        dias_maximo_prorroga_facturas: 1
      },
    }
  },

  created() {
    var self = this

    self.refrescarAjustes()
    self.hoy = moment().format('YYYY-MM-DD')
  },
  methods: {
    actualizarProrroga() {
      let self = this,
        fechaParaPago = moment(self.fecha_para_pago, 'YYYY-MM-DD'),
        fechaVencimiento = moment(self.fecha_vencimiento, 'YYYY-MM-DD'),
        diasDiferencia = fechaParaPago.diff(fechaVencimiento, 'days')
      self.prorroga.dias = diasDiferencia
    },

    guardarProrroga() {
      var self = this

      self.bandera_spinner = true

      if(self.prorroga.dias < 1) {
        iu.msg.warning("No se puede registrar una promesa de pago con la fecha menor a la fecha de vencimiento de la factura")
        self.$refs.fechaParaPago.focus()
        self.bandera_spinner = false
        return
      }

      if(self.prorroga.motivo == '') {
        iu.msg.warning('No se ha registrado un motivo de la promesa de pago para la factura')
        self.$refs.motivo.select()
        self.bandera_spinner = false
        return
      }
      
      FacturaSrv.guardarProrroga(self.prorroga, self.id_factura).then(response => {
        iu.msg.success('Se registro correctamente la promesa de pago')
        self.$emit('prorroga-factura:gardada')
        self.cerrar()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar la promesa de pago'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },
    
    cerrar() {
      var self = this

      self.id_factura = null
      self.prorroga = {
        dias: 1,
        motivo: ''
      }
      self.bandera_spinner = false
      
      var modal = Modal.getInstance(self.$refs.mdlProrrogaFactura)
      modal.hide()
    },
    mostrar(factura) {
      let self = this

      self.id_factura = factura.id
      self.fecha_para_pago = factura.fecha_para_pago
      self.fecha_para_pago_base = self.fecha_para_pago
      self.fecha_vencimiento = factura.fecha_vencimiento
      self.fecha_maxima_seleccionable = moment(self.fecha_para_pago, 'YYYY-MM-DD').add(self.ajustes.dias_maximo_prorroga_facturas, 'days').format('YYYY-MM-DD')

      var modal = new Modal(self.$refs.mdlProrrogaFactura)
      modal.show()
    },

    refrescarAjustes() {
      let self = this
      SistemaSrv.ajustes(['dias_maximo_prorroga_facturas']).then(response => {
        self.ajustes.dias_maximo_prorroga_facturas = parseInt(response.data.dias_maximo_prorroga_facturas)
      })
    },

    validarFechaParaPago() {
      let self = this
      self.fecha_para_pago = self.fecha_para_pago != '' ? self.fecha_para_pago: self.fecha_para_pago_base
    }
  },
}
</script>

<style scoped>

</style>