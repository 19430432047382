<template>
  <Layout :tituloPagina="tituloPagina">
    <div>
      <div class="row mb-4">
        <div class="col-md-6">
          <barra-paneles
            v-on:panel-seleccionado="actualizarPanel"
            :idCliente="idCliente"
            :tienePermiso="tienePermiso"
          ></barra-paneles>
        </div>
        <div class="col-md-6 mt-3">
          <cmp-opciones-reportes
            v-if="idCliente != 901 && idCliente != 902"
            :panel-activo="panel"
            :id-cliente="idCliente"
            :nombre-cliente="nombreCliente()"
            :telefonos-cliente="telefonosCliente()"
          ></cmp-opciones-reportes>
        </div>
      </div>

      <!-- Panel de información -->
      <div v-if="panel == 'informacion'">
        <div class="row mb-4">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-sm-6">
                <cmp-cuenta :id-cliente="idCliente"></cmp-cuenta>
              </div>

              <div class="col-sm-6">
                <cmp-ultimo-pago :id-cliente="idCliente"></cmp-ultimo-pago>
              </div>
            </div>

            <CmpInformacionCliente
              :id-cliente="idCliente"
              v-if="idCliente != 901 && idCliente != 902"
              :cargarAliasCampo="cargarAliasCampo"
              :tieneAlias="tieneAlias"
              @cliente-cargado="actualizarDatosCliente"
            />
          </div>
          <div class="col-lg-6">
            <CmpContratos
              :id-cliente="idCliente"
              v-if="idCliente != 901 && idCliente != 902"
            />
          </div>
        </div>
      </div>
      <!-- Fin Panel de información -->

      <!-- Panel Facturas -->
      <div v-if="panel == 'facturas'" >
        <cmp-panel-facturas :id-cliente="idCliente" :nombre-completo-cliente="nombreCompletoCliente"></cmp-panel-facturas>
      </div>
      <!-- Fin Panel Facturas -->

      <!-- Panel Pagos -->
      <div v-if="panel == 'pagos'">
        <cmp-panel-pagos :id-cliente="idCliente"></cmp-panel-pagos>
      </div>
      <!-- Fin Panel Pagos -->

      <!-- Panel Reportes -->
      <div v-if="panel == 'reportes' && idCliente != 901 && idCliente != 902">
        <cmp-panel-reportes :id-cliente="idCliente"></cmp-panel-reportes>
      </div>
      <!-- Fin Panel Reportes -->

      <!-- mdlTicketSoporte -->
      <cmp-mdl-ticket-soporte
        v-if="idCliente != 901 && idCliente != 902"
        :id-cliente="idCliente"
        :nombre-completo-cliente="nombreCompletoCliente"
        :telefonos-cliente="cliente.telefonos"
      ></cmp-mdl-ticket-soporte>
      <!-- Fin de mdl Ticket Soporte -->
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"

import BarraPaneles from './BarraPaneles.vue'
import CmpOpcionesReportes from './CmpOpcionesReportes.vue'
import CmpCuenta from './CmpCuenta.vue'
import CmpUltimoPago from './CmpUltimoPago.vue'
import CmpInformacionCliente from './CmpInformacionCliente.vue'
import CmpContratos from './CmpContratos.vue'
import CmpPanelFacturas from './CmpPanelFacturas.vue'
import CmpPanelPagos from './CmpPanelPagos.vue'
import CmpPanelReportes from './CmpPanelReportes.vue'
import CmpMdlTicketSoporte from './CmpMdlTicketSoporte.vue'

import { todoGetters } from "@/state/helpers"

import ClienteSrv from '@/services/ClienteSrv.js'

export default {
  name: 'TableroCliente',
  components: {
    Layout,
    BarraPaneles,
    CmpOpcionesReportes,
    CmpCuenta,
    CmpUltimoPago,
    CmpInformacionCliente,
    CmpContratos,
    CmpPanelFacturas,
    CmpPanelPagos,
    CmpPanelReportes,
    CmpMdlTicketSoporte
  },
  data() {
    return {
      baseUrl: window.location.origin,
      idCliente: null,
      nombreCompletoCliente: '',
      cliente: {
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        telefonos: [],
        correos: []
      },
      panel: 'informacion',
      cortesServicios: [],
      contratos: []
    }
  },
  created: function() {
    var self = this

    self.idCliente = parseInt(this.$route.params.id_cliente)

    self.cargarNombreCliente()
  },
  computed:{
    ...todoGetters,
    tituloPagina: function() {
      var self = this
      return `Clientes | Tablero ${self.nombreCompletoCliente}`
    }
  },
  methods: {
    actualizarDatosCliente: function(cliente) {
      this.cliente = cliente
    },

    actualizarPanel: function(nombrePanel) {
      this.panel = nombrePanel != '' ? nombrePanel : 'informacion'
    },

    cargarNombreCliente: function() {
      var self = this
      ClienteSrv.nombreCompleto(this.$route.params.id_cliente).then(response => {
        self.nombreCompletoCliente = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (error) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje):null
        console.log(error)
      })
    },
    nombreCliente: function() {
      var cliente = this.cliente
      return (
        cliente.nombre +
        ' ' +
        cliente.apellido_paterno +
        ' ' +
        cliente.apellido_materno
      )
    },

    mostrarListaCortes: function(cortesServicios) {
      mdlCortes.mostrar(cortesServicios)
    },

    telefonosCliente: function() {
      var cliente = this.cliente
      var cadenaTelefonos = ''

      cliente.telefonos.forEach(function(telefono, index) {
        cadenaTelefonos += telefono.numero
        if (index + 1 < cliente.telefonos.length) cadenaTelefonos += ', '
      })

      return cadenaTelefonos
    }
  },
}
</script>

<style scoped>

</style>