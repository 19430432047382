<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">
        Contratos de servicios
        <div class="bx-pull-right">
          <a
            href="#"
            class="btn btn-link btn-sm"
            @click="nuevoContrato()"
            onclick="return false;"
            v-show="contratos.length > 0"
          >
            <i class="mdi mdi-plus"></i>
            Nuevo
          </a>
        </div>
      </h4>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-2">
          <div class="form-check form-switch form-switch-lg mb-3">
            <input
              v-model="contratosActivos"
              @change="refrescarContratos()"
              class="form-check-input"
              type="checkbox"
              id="palancaActivos"
            />
            <label class="form-check-label" for="palancaActivos">Activos</label>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-hover font-size-13 align-middle table-nowrap">
          <thead>
            <tr>
              <th>#ID</th>
              <th>Servicio</th>
              <th>CPE</th>
              <th v-if="contratosActivos">Dirección IP</th>
              <th v-if="contratosActivos">Estado</th>
              <th style="width: 170px">Acción</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="contrato in contratos" :key="contrato.id">
              <td>
                <router-link
                  class="btn btn-sm"
                  :to="{
                    name: 'edicionContrato',
                    params: { id_contrato: contrato.id }
                  }"
                  :class="{
                    'btn-light': contrato.cancelado == false,
                    'btn-outline-danger': contrato.cancelado == true
                  }"
                >
                  {{ contrato.id }}
                </router-link>
              </td>
              <td>
                <div v-if="contrato.servicio != null">
                  {{ contrato.servicio.nombre }}
                  <sup
                    class="badge badge-pill badge-soft-info"
                    v-show="contrato.id_tipo_renta == 1"
                    title="Renta Mensual Fija"
                  >
                    RMF
                  </sup>
                  <sup
                    class="badge badge-pill badge-soft-warning"
                    v-show="contrato.id_tipo_renta == 2"
                    title="Renta Mensual Variable"
                  >
                    RMV
                  </sup>
                </div>
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'edicionCpe',
                    params: { id: contrato.cpe.id }
                  }"
                  v-if="contrato.cpe != null"
                >
                  {{ contrato.cpe.nombre }}
                </router-link>
              </td>
              <td v-if="contratosActivos == true">
                <span v-if="contrato.cpe != null">
                  {{ contrato.cpe.ip_wan }}
                </span>
              </td>
              <td v-if="contratosActivos == true">
                <span
                  v-show="contrato.cpe != null && contrato.cpe.enlace_activo"
                  class="badge bg-success bg-gradient"
                >
                  ACTIVO
                </span>
                <span
                  v-show="
                    contrato.cpe != null &&
                      contrato.cpe.enlace_activo == false
                  "
                  class="badge bg-danger bg-gradient"
                >
                  SUSPENDIDO
                </span>
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'edicionContrato',
                    params: { id_contrato: contrato.id }
                  }"
                  class="btn btn-info bg-gradient btn-sm font-size-10"
                >
                  <i class="mdi mdi-eye"></i>
                  Mostrar
                  <span v-if="contrato.cancelado == 1">cancelado</span>
                </router-link>
                <button
                  class="btn btn-danger bg-gradient btn-sm font-size-10"
                  @click="preguntaCancelarContrato(contrato)"
                  v-if="contrato.cancelado == 0"
                >
                  <i class="mdi mdi-close"></i>
                  Cancelar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br>
      <GoogleMap
        ref="mapa"
        :api-key="apikeyGoogle"
        :center="center"
        style="height: 400px;"
        :zoom="zoom"
        :styles="modoTemplate == 'dark' ? styles_dark : styles_light"
        v-if="contratos.length > 0"
      >
        <MarkerCluster>
          <Marker 
            v-for="(location, i) in locations"
            :options="{ 
              position: location.position, 
              icon: location.icon, 
              title: location.title 
            }"
            @dblclick="zoomChanged($event, location.position)"
            :key="i"
          >
            <InfoWindow>
              <div id="contet">
                <div id="siteNotice"></div>
                <h5 id="firstHeading" class="firstHeading">
                  {{location.contrato.cpe != null ? location.contrato.cpe.nombre : 'Servicio ' + location.contrato.servicio.id}}
                </h5>
                <div id="bodyContent">
                  <p>
                    <strong>Servicio: </strong>{{ location.contrato.servicio.nombre }}
                    <sup
                      class="badge badge-pill badge-soft-info"
                      v-show="location.contrato.id_tipo_renta == 1"
                      title="Renta Mensual Fija"
                    >
                      RMF
                    </sup>
                    <sup
                      class="badge badge-pill badge-soft-warning"
                      v-show="location.contrato.id_tipo_renta == 2"
                      title="Renta Mensual Variable"
                    >
                      RMV
                    </sup>

                    <br v-if="location.contrato.cpe != null">

                    <strong v-if="location.contrato.cpe != null">Direción IP: </strong>
                    <span v-if="location.contrato.cpe != null">
                      {{ location.contrato.cpe.ip_wan }}
                    </span>

                    <br v-if="location.contrato.cpe != null">
                    <strong v-show="location.contrato.cpe != null">Estado: </strong>
                    <span
                      v-show="location.contrato.cpe != null && location.contrato.cpe.enlace_activo"
                      class="badge bg-success bg-gradient"
                    >
                      ACTIVO
                    </span>
                    <span
                      v-show="
                        location.contrato.cpe != null &&
                        location.contrato.cpe.enlace_activo == false
                      "
                      class="badge bg-danger bg-gradient"
                    >
                      SUSPENDIDO
                    </span>
                    <br>
                    <br>
                    <a :href="'https://maps.google.com/?q='+location.contrato.latitud+','+location.contrato.longitud+'&entry=gps'" target="_blank">
                      Ver en google maps
                    </a>
                  </p>
                </div>
              </div>
            </InfoWindow>
          </Marker>
        </MarkerCluster>
      </GoogleMap>
      <div class="d-grid gap-2">
        <button
          class="btn btn-success bg-gradient btn-rounded"
          @click="nuevoContrato()"
          v-show="contratos.length == 0"
        >
          <i class="mdi mdi-clipboard-plus-outline"></i>
          Agregar nuevo contrato
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { GoogleMap, Marker, MarkerCluster, InfoWindow } from "vue3-google-map"

import markerIcon  from '@/assets/img/location24x36.png'
import ClienteSrv  from '@/services/ClienteSrv.js'
import ContratoSrv from '@/services/ContratoSrv.js'

import Swal from 'sweetalert2'

export default {
  name: 'CmpContratos',
  components: { 
    GoogleMap,
    MarkerCluster,
    Marker,
    InfoWindow
  },
  props: {
    idCliente: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      contratos: [],
      cpes: [],
      mapa: null,
      contratosActivos: true,
      zoom: 12,
      
      markerIcon:markerIcon,

      center: { lat: 0, lng: 0 },
      locations: [],
      styles_dark: [
        {
          featureType: "all",
          elementType: "all",
          stylers: [
            { invert_lightness: true },
            { saturation: 10 },
            { lightness: 30 },
            { gamma: 0.5 },
            { hue: "#435158" }
          ]
        },{
          featureType: "poi",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 },
            { lightness: 0 }
          ]
        },{
          featureType: "transit",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 },
            { lightness: 0 }
          ]
        },{
          featureType: "road",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -60 }
          ]
        }
      ],
      styles_light:[
        {
          featureType: "administrative",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 20 }
          ]
        },{
          featureType: "road",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 40 }
          ]
        },{
          featureType: "water",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -10 },
            { lightness: 30 }
          ]
        },{
          featureType: "landscape.man_made",
          elementType: "all",
          stylers: [
            { visibility: "simplified" },
            { saturation: -60 },
            { lightness: 10 }
          ]
        },{
          featureType: "landscape.natural",
          elementType: "all",
          stylers: [
            { visibility: "simplified" },
            { saturation: -60 },
            { lightness: 60 }
          ]
        },{
          featureType: "poi",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 60 }
          ]
        },{
          featureType: "transit",
          elementType: "all",
          stylers: [
            { visibility: "on" },
            { saturation: -100 },
            { lightness: 60 }
          ]
        }
      ]
    }
  },
  computed: {
    modoTemplate: function() {
      return this.$store.state.layout.layoutMode
    },
    apikeyGoogle() {
      return this.$store.state.todo.apiKeyGoogleMaps
    }
  },

  created: function() {
    var self = this

    self.refrescarContratos() // Carga inicial de los contratos asignados al cliente

    // Inicialización del mapa
    iu.bus.on('contratos-cargados', function() {
      if (self.contratos.length == 0) return
      self.iniciarMapa()
    })

    iu.bus.on('mapa-creado', self.agregarServiciosEnMapa)
  },

  methods: {
    agregarServiciosEnMapa: function() {
      var self = this
      var servicios = self.contratos

      self.locations = []

      servicios.forEach(function(servicio) {
        var info = {
          position: { lat: servicio.latitud, lng: servicio.longitud },
          title: servicio.cpe != null ? servicio.cpe.nombre : '',
          icon: markerIcon,
          contrato: servicio
        }

        self.locations.push(info)
      })
    },

    cancelarContrato: function(contrato) {
      var self = this

      ContratoSrv.cancelar(contrato.id).then(response => {
        self.refrescarContratos()
        iu.msg.success('Se canceló el contrato correctamente')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cancelar el contrato'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    iniciarMapa: function() {
      var self = this

      var posTmp = self.posicionCentralPromedioServicios() // Obtención de la posición central promediada
      
      self.center = {
        lat: posTmp.latitud,
        lng: posTmp.longitud
      }

      iu.bus.emit('mapa-creado')
    },

    nuevoContrato: function() {
      this.$router.push({
        name: 'nuevoContrato'
      })
    },

    /**
     * Devuelve la posicion central promedio de todos los servicios
     * registrados al cliente
     */
    posicionCentralPromedioServicios: function() {
      var self = this
      var latitudPromedio = 0
      var longitudPromedio = 0
      var nContratos = 0

      self.contratos.forEach(function(contrato, index) {
        latitudPromedio += contrato.latitud
        longitudPromedio += contrato.longitud
        nContratos++
      })

      latitudPromedio /= nContratos
      longitudPromedio /= nContratos

      return {
        latitud: latitudPromedio,
        longitud: longitudPromedio
      }
    },

    preguntaCancelarContrato: function(contrato) {
      var self = this
      
      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "Cancelación de contrato",
        html: `¿Está seguro de cancelar el contrato ${contrato.id}?`,
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          ContratoSrv.cancelar(contrato.id).then(response => {
            swal.fire("Cancelado!", "Se canceló el contrato correctamente", "success")
            self.refrescarContratos()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo cancelar el contrato'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error")
        }
      })
    },

    refrescarContratos: function() {
      var self = this

      let params = {
        con_servicio: true,
        con_cpe: true,
        activos: self.contratosActivos == true
      }

      ClienteSrv.contratosJSON(self.idCliente, params).then(response => {
        self.contratos = response.data
        iu.bus.emit('contratos-cargados')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    zoomChanged: function(event, position) {
      var self = this,
          zoom = this.$refs.mapa.map.zoom

      self.center.lat = 0
      self.center.lng = 0

      self.zoom = zoom + 2

      self.center = {
        lat: parseFloat(position.lat),
        lng: parseFloat(position.lng)
      }
    }
  }
}
</script>