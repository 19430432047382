<template>
  <div>
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card">
          <div class="card-body">
            Gran total facturado: {{ hrr.fmon(totalFacturasCliente, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6 col-md-6 offset-lg-6 text-right mb-2 mt-2">
        <router-link :to="{ name: 'nuevaFactura', params: {id_cliente: idCliente} }" class="btn btn-success bg-gradient">
          <span class="d-none d-sm-block">
            <i class="mdi mdi-plus-thick"></i>
            Nueva factura
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-plus-thick"></i>
          </span>
        </router-link>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md-12">
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="creacion"
            v-model="columnas.fechaCreacion"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="creacion">Fecha de creación</label>
        </div>
        &nbsp;
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="pago"
            v-model="columnas.fechaPago"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="pago">Fecha para pago</label>
        </div>
        &nbsp;
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="vencimiento"
            v-model="columnas.fechaVencimiento"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="vencimiento">Fecha de vencimiento</label>
        </div>
        &nbsp;
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="corte"
            v-model="columnas.fechaCorte"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="corte">Fecha de corte</label>
        </div>
        &nbsp;
        <div class="form-check font-size-16 d-inline-block">
          <input
            class="form-check-input"
            type="checkbox"
            id="inicioFinPeriodo"
            v-model="columnas.inicioYFinPeriodo"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="inicioFinPeriodo">
            Inicio y fin de periodo
          </label>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive" style="min-height: 475px;">
          <table id="tblPanelFacturas" class="table table-hover table-nowrap">
            <thead>
              <tr>
                <th>Factura</th>
                <th>Id de contrato</th>
                <th>Total</th>
                <th>Saldo</th>
                <th style="width: 100px" v-show="columnas.fechaCreacion">Fecha de creación</th>
                <th style="width: 100px" v-show="columnas.fechaPago">Fecha para pago</th>
                <th style="width: 100px" v-show="columnas.fechaVencimiento">Fecha de vencimiento</th>
                <th style="width: 100px" v-show="columnas.fechaCorte">Fecha de corte</th>
                <th style="width: 100px" v-show="columnas.inicioYFinPeriodo">Inicio de periodo</th>
                <th style="width: 100px" v-show="columnas.inicioYFinPeriodo">Fin de periodo</th>
                <th style="width: 165px">Estado</th>
                <th style="width: 135px" class="text-center">Acción</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="factura in facturas" >
                <tr v-if="true" :key="`factura-${factura.id}`">
                  <td class="fw-semibold">
                    <router-link :to="{ name: 'factura', params: { id: factura.id } }">
                      {{ factura.ns }}
                    </router-link>
                    <sup
                      title="Generada por sistema"
                      class="badge badge-pill badge-soft-info"
                      v-show="factura.generada_por_sistema == true"
                    >GS</sup>
                    <sup
                      title="Factura anticipada"
                      class="badge badge-pill badge-soft-dark"
                      v-show="factura.anticipada == true"
                    >FA</sup>
                    <sup
                      v-show="factura.prorroga > 0"
                      class="badge badge-pill badge-soft-success font-size-11"
                      title="Tiene promesa de pago"
                    >P</sup>
                  </td>
                  <td>
                    <router-link
                      :to="{ 
                        name: 'edicionContrato', 
                        params: { id_cliente: factura.id_cliente, id_contrato: factura.id_contrato} 
                      }"
                      v-if="factura.id_contrato != null"
                    >
                      {{factura.id_contrato}}
                    </router-link>
                  </td>
                  <td>
                    {{ hrr.fmon(factura.total, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                  </td>
                  <td>
                    <router-link
                      :to="{
                        name: 'abonosFactura',
                        params: { id_factura: factura.id }
                      }"
                    >
                      {{ hrr.fmon(factura.saldo, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                    </router-link>
                  </td>
                  <td v-show="columnas.fechaCreacion">
                    {{ formatoFecha(factura.fecha+' '+factura.hora_creacion, 'DD/MM/YYYY - hh:mm A' ) }}
                  </td>
                  <td v-show="columnas.fechaPago">
                    {{ factura.fecha_para_pago ? formatoFecha(factura.fecha_para_pago) : '' }}
                  </td>
                  <td v-show="columnas.fechaVencimiento">
                    {{ factura.fecha_vencimiento ? formatoFecha(factura.fecha_vencimiento): null }}
                  </td>
                  <td v-show="columnas.fechaCorte">
                    <span v-show="factura.id_tipo_renta_contrato == 1">
                      {{ fecha_corte_servicio(factura.fecha_para_pago ? factura.fecha_para_pago : '' , factura.n_dias_corte ? factura.n_dias_corte : 0 ) }}
                    </span>
                    <span v-show="factura.id_tipo_renta_contrato == 2">
                      {{factura.fin_periodo ? formatoFecha(factura.fin_periodo): ''}}
                    </span>
                  </td>
                  <td v-show="columnas.inicioYFinPeriodo">{{ factura.inicio_periodo? formatoFecha(factura.inicio_periodo): null}}</td>
                  <td v-show="columnas.inicioYFinPeriodo">{{ factura.fin_periodo? formatoFecha(factura.fin_periodo): null}}</td>
                  <td>
                    <span
                      v-show="factura.pagada == false && factura.cancelada == false"
                      class="badge bg-warning bg-gradient"
                    >
                      Sin cobrar
                    </span>
                    <span v-show="factura.cancelada" class="badge bg-danger bg-gradient">
                      Cancelada
                    </span>
                    <span
                      v-show="factura.pagada && !factura.cancelada"
                      class="badge bg-success bg-gradient"
                    >
                      Pagada
                    </span>
                    <br>
                    <span
                      class="badge bg-info bg-gradient"
                      v-show="
                        !factura.pagada &&
                        !factura.cancelada &&
                        factura.prorroga > 0
                      "
                    >
                      Promesa de pago
                    </span>

                    <button
                      v-show="
                        !factura.pagada &&
                        !factura.cancelada &&
                        factura.prorroga > 0
                      "
                      class="btn btn-light btn-sm"
                      title="Promesa de pago"
                      @click="palancaMostrarProrroga(factura)"
                    >
                      <i
                        class="mdi mdi-chevron-down"
                        v-show="factura.mostrar_prorroga == false"
                      ></i>
                      <i
                        class="mdi mdi-chevron-up"
                        v-show="factura.mostrar_prorroga == true"
                      ></i>
                    </button>
                  </td>
                  <td class="text-center">

                    <div class="d-flex flex-wrap gap-2">
                      <router-link
                        :to="{
                          name: 'facturasPendientesPago',
                          params: { id_cliente: factura.id_cliente }
                        }"
                        class="btn btn-success btn-sm"
                        v-if="
                          factura.pagada == false && factura.cancelada == false
                        "
                        title="Cobrar"
                      >
                        <i class="mdi mdi-cash"></i>
                      </router-link>
                      <router-link
                        :to="{ name: 'factura', params: { id: factura.id } }"
                        class="btn btn-info btn-sm"
                        title="Ver"
                      >
                        <i class="mdi mdi-eye"></i>
                      </router-link>
                      <div class="dropdown">
                        <button
                          class="btn btn-light btn-sm dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i class="bx bx-dots-horizontal-rounded"></i>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end">
                          <li>
                            <button class="dropdown-item dark" @click="imprimirFactura(factura)">
                              <i class="mdi mdi-printer text-dark"></i>
                              Imprimir
                            </button>
                          </li>
                          <li>
                            <button
                              class="dropdown-item"
                              :class="factura.prorroga == 0 ? 'primary': 'danger'"
                              v-show="!factura.cancelada && !factura.pagada"
                              @click="factura.prorroga == 0 ? mostrarMdlProrrogaFactura(factura) : cancelarProrroga(factura)"
                            >
                              <i class="mdi mdi-clock-outline" :class="factura.prorroga == 0 ? 'text-primary': 'text-danger'"></i>
                              {{factura.prorroga == 0 ? 'Promesa de pago' : 'Cancelar promesa de pago'}}
                            </button>
                          </li>
                          <li>
                            <button
                              class="dropdown-item danger"
                              v-show="!factura.cancelada && !factura.pagada"
                              @click="preguntaCancelarFactura(factura)"
                            >
                              <i class="mdi mdi-cancel text-danger"></i>
                              Cancelar
                            </button>
                          </li>
                          <li>
                            <button
                              class="dropdown-item danger"
                              v-show="factura.cancelada"
                              @click="preguntaEliminarFactura(factura)"
                            >
                              <i class="mdi mdi-trash-can-outline text-danger"></i>
                              Eliminar
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr v-if="factura.mostrar_prorroga" :key="'factura-prorroga-'+factura.id">
                  <td colspan="3">
                    <strong>Días después de la fecha de vencimiento:</strong> {{ factura.prorroga }} dia{{factura.prorroga==0?'':'s'}}
                  </td>
                  <td colspan="3">
                    <strong>Motivo de la promesa de pago:</strong> {{ factura.motivo_prorroga }}
                  </td>
                  <td colspan="2">
                    <strong>Promesa de pago aplicada por:</strong> {{ factura.nombre_usuario_prorroga }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center">
      <paginador
        :pag="pagina"
        :totpag="ultima_pagina"
        :epp="por_pagina"
        v-on:cargar-pagina="cargarPagina"
        v-on:epp-actualizado="
          por_pagina = $event,
          refrescarFacturas()
        "
      ></paginador>
    </div>
    <mdl-prorroga-factura
      ref="mdlProrrogaFactura"
      @prorroga-factura:gardada="refrescarFacturas()"
    />
  </div>
</template>

<script>
/**
 * Componente PANEL FACTURAS
 */
import API from '@/API.js'
import Paginador from '@/components/Paginador.vue'
import ClienteSrv from '@/services/ClienteSrv.js'
import MdlProrrogaFactura from '../facturas/MdlProrrogaFactura.vue'
import FacturaSrv from '@/services/FacturaSrv.js'
import Swal from 'sweetalert2'
import moment from 'moment'
import md5 from 'md5'

export default {
  name: 'CmpPanelFacturas',
  props: {
    idCliente: {
      type: Number,
      required: true
    },
    nombreCompletoCliente: {
      type: String,
      required: true
    }
  },
  components: { 
    Paginador,
    MdlProrrogaFactura
  },
  data() {
    return {
      API: API,
      token: localStorage.getItem('argusblack.token'),
      hrr: iu.hrr,
      facturas: [],
      facturasSeleccionadas: [],
      totalFacturasCliente: 0,
      pagina: 1,
      por_pagina: parseInt(localStorage.getItem('argusblack.cmpPanelFacturas.por_pagina') || 10),
      total: 0,
      todasSeleccionadas: false,
      ultima_pagina: 0,
      columnas: {
        fechaCreacion: true,
        fechaPago: true,
        fechaVencimiento: true,
        fechaCorte: false,
        inicioYFinPeriodo: false
      }
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created: function() {
    let self = this
    // Carga del ID del cliente
    this.refrescarFacturas()

    // Detecta cuando hay un cambio en las facturas, para en dicho caso volver a cargarlas
    iu.bus.on('facturas-actualizadas', this.refrescarFacturas)
    this.cargarTotalFacturas()

    // Carga de las columnas visibles
    self.cargarValoresColumnasDeLocalStorage()
  },
  methods: {
    actualizarValoresColumnasEnLocalStorage() {
      let self = this
      localStorage.setItem('argusblack.tableroCliente.listadoFacturas.columnas', JSON.stringify(self.columnas))
    },
    cargarValoresColumnasDeLocalStorage() {
      let self = this
      let columnas = localStorage.getItem('argusblack.tableroCliente.listadoFacturas.columnas')
      
      if(columnas == null) return

      columnas = JSON.parse(columnas)
      Object.assign(self.columnas, columnas)
    },
    cancelarFactura(idFactura, params) {
      var self = this
      params = params || {}

      FacturaSrv.cancelar(idFactura, params).then(response => {
        iu.msg.success('Factura cancelada correctamente.')
        self.refrescarFacturas()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cancelar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    fecha_corte_servicio: function(fecha_para_pago = '', n_dias_corte = 0) {
      if(fecha_para_pago == '') return ''
      return moment(fecha_para_pago, 'YYYY-MM-DD').add(n_dias_corte, 'days').format('DD/MM/YYYY')
    },
    formatoFecha(mt, formato = 'DD/MM/YYYY'){
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format(formato)
    },
    imprimirFactura(factura) {
      let url = API+"/facturas/"+factura.id+"/impresion/"+md5(factura.id.toString())
      window.open(url,'_blank')
    },
    refrescarFacturas() {
      var self = this
      
      iu.spinner.mostrar('#tblPanelFacturas')

      let params = {
        pagina: self.pagina,
        por_pagina: parseInt(self.por_pagina),
        orden: 'desc'
      }

      ClienteSrv.facturasJSON(self.idCliente, params).then(response => {
        localStorage.setItem('argusblack.cmpPanelFacturas.por_pagina', self.por_pagina)
        let res = response.data
        self.total = res.total
        self.ultima_pagina = res.ultima_pagina

        let facturas = res.facturas

        facturas.forEach(function(factura) {
          factura.mostrar_prorroga = false
        })

        self.facturas = facturas

        self.todasSeleccionadas = false
        self.facturasSeleccionadas = []
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las facturas del cliente'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tblPanelFacturas')
      })
    },
    cargarPagina: function(n) {
      this.pagina = n
      this.refrescarFacturas()
    },
    cancelarProrroga: function(factura) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "¿Cancelar promesa de pago?",
        html: "¿Está seguro de cancelar la promesa de pago para la factura "+factura.ns+"?",
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          FacturaSrv.eliminarProrroga(factura.id).then(response => {
            swal.fire("¡Promesa de pago cancelada!",  "Promesa de pago de la factura "+factura.ns+" cancelada.", "success")
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = "No se pudo cancelar la promesa de pago de la factura "+factura.ns
            }
            swal.fire("Error", mensaje, "error");
            console.log(error)
          }).finally(firn => {
            self.refrescarFacturas()
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error")
        }
      })
    },
    mostrarMdlProrrogaFactura(factura) {
      this.$refs.mdlProrrogaFactura.mostrar(factura)
    },
    palancaMostrarProrroga: function(factura) {
      if (factura.mostrar_prorroga) factura.mostrar_prorroga = false
      else factura.mostrar_prorroga = true
    },
    cargarTotalFacturas: function() {
      var self = this

      ClienteSrv.totalFacturas(self.idCliente).then(response => {
        self.totalFacturasCliente = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    },
    facturasSeleccionadasActualizadas: function() {
      var self = this

      // Desactiva el checkbox de selección de todas las facturas si la cantidad de facturas seleccionadas es
      // menor que la cantidad de facturas
      if (
        self.todasSeleccionadas &&
        self.facturasSeleccionadas.length < self.facturas.length
      )
        self.todasSeleccionadas = false

      iu.bus.emit('facturas-seleccionadas-actualizadas', self.facturasSeleccionadas)
    },
    preguntaCancelarFactura: function(factura) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "Cancelación de factura",
        html: '¿Desea cancelar la factura <strong>'+factura.ns+'</strong> '+'del cliente <strong>'+self.nombreCompletoCliente+'</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          if(factura.id_contrato != null && factura.fecha_para_pago != null) {
            swal.fire("¡Cancelación de factura!",  "Cancelación de factura "+factura.ns, "success")
            setTimeout(function(){
              swal.fire({
                title:  'Regeneración de factura',
                html:  '¿Deseas que el sistema vuelva a regenerar la factura?',
                icon: "info",
                confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
                cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
                showCancelButton: true
              }).then(result => {
                if (result.value) {
                  self.cancelarFactura(factura.id, {regenerar: true})
                } else if ( result.dismiss === Swal.DismissReason.cancel ) {
                  self.cancelarFactura(factura.id)
                }
              })
            }, 300)
          }
          else {
            self.cancelarFactura(factura.id)
          }
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error")
        }
      })
    },
    preguntaEliminarFactura: function(factura) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Eliminación de factura",
        html: '¿Desea elimnar la factura <strong>' + factura.folio + '</strong> ' +
          'del cliente <strong>' + factura.nombre_cliente + '</strong>?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          FacturaSrv.eliminar(factura.id).then(resopnse => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success");
            self.refrescarFacturas()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'Mp se pudo eliminar la factura'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    }
  },
  mounted: function (){
    iu.spinner.mostrar('#tblPanelFacturas')
  }
}
</script>
<style scoped>
</style>