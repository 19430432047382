<template>
  <div class="card">
    <div class="card-body p-0">
      <div class="user-profile-img">
        <img
          src="@/assets/images/pattern-bg.jpg"
          class="profile-img profile-foreground-img rounded-top"
          style="height: 100px"
        />
        <div class="overlay-content rounded-top">
          <div>
            <div class="user-nav p-3">
              <div class="d-flex justify-content-end">
                <div class="dropdown">
                  <a
                    class="dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <eva-icon
                      name="more-horizontal-outline"
                      data-eva-width="20"
                      data-eva-height="20"
                      class="fill-white"
                    ></eva-icon>
                  </a>
                  <ul class="dropdown-menu dropdown-menu-end">
                    <li v-show="foto_cliente">
                      <button class="dropdown-item" @click="visibleImagen = true">
                        <i class="fas fa-eye text-info"></i>
                        Ver imagen completa
                      </button>
                    </li>
                    <li>
                      <router-link
                        :to="{ name: 'edicionCliente' }"
                        class="dropdown-item warning" title="Edición del cliente"
                        v-if="idCliente != 900 && idCliente != 901 && idCliente != 902"
                      >
                        <i class="fas fa-edit text-warning"></i>
                        Edición del cliente
                      </router-link>
                    </li>
                    <li v-if="cliente.activo == true && idCliente != 900 && idCliente != 901 && idCliente != 902">
                      <button
                        id="btnRetirarCliente" class="dropdown-item danger" title="Retirar cliente"
                        @click="preguntaRetirarCliente( idCliente, nombreCliente() )"
                        v-if="cliente.activo == true && idCliente != 900 && idCliente != 901 && idCliente != 902"
                      >
                        <i class="fas fa-archive text-danger"></i>
                        Retirar cliente
                      </button>
                    </li>
                    <li v-if="cliente.activo != true && cliente.id != 900 && cliente.id != 901 && cliente.id != 902">
                      <button
                        class="dropdown-item success"
                        @click="preguntaRestaurarCliente( idCliente, nombreCliente() )"
                        v-if="cliente.activo != true && cliente.id != 900 && cliente.id != 901 && cliente.id != 902"
                      >
                        <i class="mdi mdi-check-circle-outline text-success"></i>
                        Restaurar cliente
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end user-profile-img -->
      <div class="mt-n5 position-relative">
        <div class="mb-1 text-center" v-show="foto_cliente">
          <div v-show="foto == null">
            <img
              :src="clienteIcon"
              alt="Foto del cliente"
              class="avatar-xl rounded-circle"
            />
          </div>
          <div v-if="foto != null">
            <img
              :src="foto"
              class="avatar-xl rounded-circle img-thumbnail cursor"
              alt="Foto del cliente"
              @click="visibleImagen = true"
            />
            <vue-easy-lightbox
              :visible="visibleImagen"
              :imgs="foto_visor"
              @hide="visibleImagen = false"
            ></vue-easy-lightbox>
          </div>
        </div>
        <div class="text-center">
          <div :class="{ 'mt-6': !foto_cliente, 'mt-3': foto_cliente }">
            <h5 class="mb-3">
              {{ nombreCliente() }}
              <br>
              <span
                class="badge bg-danger bg-gradient font-size-12"
                v-if="!cliente.activo"
              >
                Retirado
              </span>
            </h5>
            <div class="ml mr text-left">
              <p class="text-muted mb-0 mt-2">
                <i class="mdi mdi-music-accidental-sharp font-size-20 align-middle pe-2 text-primary"></i>
                <strong>Número de cliente:</strong>
                {{ cliente.numero }}
              </p>
              <p class="text-muted mb-0 mt-2" v-show="tieneAlias('Calle')">
                <i class="mdi mdi-google-maps font-size-20 align-middle pe-2 text-primary"></i>
                <span v-if="tieneAlias('Calle')">
                  <b>{{cargarAliasCampo('Calle')}}</b> {{ cliente.calle }},
                </span>
                <span v-if="!atributoVacio(cliente.numero_exterior) && tieneAlias('N° ext')">
                  <b>{{cargarAliasCampo('N° ext')}}</b> {{ cliente.numero_exterior }},
                </span>
                <span v-if="!atributoVacio(cliente.numero_interior) && tieneAlias('N° int')">
                  <b>{{cargarAliasCampo('N° int')}}</b> {{ cliente.numero_interior }},
                </span>
                <span v-if="!atributoVacio(cliente.colonia) && tieneAlias('Colonia')">
                  <b>{{cargarAliasCampo('Colonia')}}</b> {{ cliente.colonia }},
                </span>
                <span v-if="!atributoVacio(cliente.municipio) && tieneAlias('Delegación o Municipio')">
                  <b>{{cargarAliasCampo('Delegación o Municipio')}}</b> {{ cliente.municipio }},
                </span>
                <span v-if="!atributoVacio(cliente.codigo_postal) && tieneAlias('Código postal')">
                  <b>{{cargarAliasCampo('Código postal')}}</b> {{ cliente.codigo_postal }},
                </span>
                <span v-if="!atributoVacio(cliente.estado_residencia) && tieneAlias('Estado de residencia')">
                  <b>{{cargarAliasCampo('Estado de residencia')}}</b> {{ cliente.estado_residencia }},
                </span>
                <span v-if="!atributoVacio(cliente.pais) && tieneAlias('País')">
                  <b>{{cargarAliasCampo('País')}}</b> {{ cliente.pais }}
                </span>
              </p>
              <p class="text-muted mb-0" v-if="tieneAlias('Teléfonos')">
                <i class="mdi mdi-phone font-size-20 align-middle pe-2 text-primary"></i>
                <strong>{{ cargarAliasCampo('Teléfonos') }}: </strong>
                {{ cadenaTelefonosCliente() }}
              </p>
              <p class="text-muted mb-0 mt-2" v-if="tieneAlias('Correo electrónico')">
                <i class="mdi mdi-email font-size-20 align-middle pe-2 text-primary"></i>
                <strong>{{cargarAliasCampo('Correo electrónico')}}: </strong>
                {{ cliente.correo }}
              </p>
              <p class="text-muted mb-0 mt-2" v-if="tieneAlias('Messenger')">
                <i class="mdi mdi-facebook font-size-20 align-middle pe-2 text-primary"></i>
                <strong>{{cargarAliasCampo('Messenger')}}: </strong>
                <template v-if="cliente.messenger != null">
                  <span v-show="cliente.messenger.substring(0,4) != 'http'">
                    @
                  </span>
                  <a :href="cliente.messenger.substring(0,4) == 'http'?cliente.messenger:`https://facebook.com/${cliente.messenger}`" target="_blank">
                    {{cliente.messenger}}
                  </a>
                </template>
              </p>
              <p class="text-muted mb-0 mt-2" v-if="tieneAlias('URL 1')">
                <i class="mdi mdi-google-chrome font-size-20 align-middle pe-2 text-primary"></i>
                <strong>{{cargarAliasCampo('URL 1')}}: </strong>
                <a v-if="cliente.url1 != null" :href="cliente.url1" target="_blank">
                  {{cliente.url1}}
                </a>
              </p>
              <p class="text-muted mb-0 mt-2" v-if="tieneAlias('URL 2')">
                <i class="mdi mdi-google-chrome font-size-20 align-middle pe-2 text-primary"></i>
                <strong>{{cargarAliasCampo('URL 2')}}: </strong>
                <a v-if="cliente.url2 != null" :href="cliente.url2" target="_blank">
                  {{cliente.url2}}
                </a>
              </p>
              <p class="text-muted mb-0 mt-2" v-if="tieneAlias('Tipo de cliente')">
                <i class="mdi mdi-ev-plug-type2 font-size-20 align-middle pe-2 text-primary"></i>
                <strong>{{cargarAliasCampo('Tipo de cliente')}}: </strong>
                {{ cliente.empresa == true ? 'EMPRESA' : 'Persona' }}
              </p>
              <p class="text-muted mb-0 mt-2" v-if="tieneAlias('Panel de cliente')">
                <i class="mdi mdi-solar-panel font-size-20 align-middle pe-2 text-primary"></i>
                <strong>{{cargarAliasCampo('Panel de cliente')}}: </strong>
                <br>
                <b class="ml">Número de cliente:</b>
                {{ cliente.prefnumcli }}-{{ cliente.numero }}
                <br>
                <b class="ml">Clave de acceso:</b>
                <span v-if="claveVisible == false">&nbsp;**********</span>
                <span v-if="claveVisible">&nbsp;{{ cliente.clave_sin_encriptar }}</span>
                <button
                  class="btn btn-light btn-sm"
                  v-on:click="claveVisible = true"
                  v-if="claveVisible == false"
                >
                  <i class="mdi mdi-eye-outline align-middle"></i>
                </button>
                <button
                  class="btn btn-light btn-sm"
                  v-on:click="claveVisible = false"
                  v-if="claveVisible == true"
                >
                  <i class="mdi mdi-eye-off-outline align-middle"></i>
                </button>
              </p>
              <p class="text-muted mb-0 mt-2" v-if="tieneAlias('Dato auxiliar 1')">
                <i class="mdi mdi-book-account-outline font-size-20 align-middle pe-2 text-primary"></i>
                <strong>{{cargarAliasCampo('Dato auxiliar 1')}}: </strong>
                {{cliente.datoaux1}}
              </p>
              <p class="text-muted mb-0 mt-2" v-if="tieneAlias('Dato auxiliar 2')">
                <i class="mdi mdi-book-account-outline font-size-20 align-middle pe-2 text-primary"></i>
                <strong>{{cargarAliasCampo('Dato auxiliar 2')}}: </strong>
                {{cliente.datoaux2}}
              </p>
              <p class="text-muted mb-0 mt-2" v-if="tieneAlias('Dato auxiliar 3')">
                <i class="mdi mdi-book-account-outline font-size-20 align-middle pe-2 text-primary"></i>
                <strong>{{cargarAliasCampo('Dato auxiliar 3')}}: </strong>
                {{cliente.datoaux3}}
              </p>
              <p class="text-muted mb-0 mt-2" v-if="tieneAlias('Dato auxiliar 4')">
                <i class="mdi mdi-book-account-outline font-size-20 align-middle pe-2 text-primary"></i>
                <strong>{{cargarAliasCampo('Dato auxiliar 4')}}: </strong>
                {{cliente.datoaux4}}
              </p>
            </div>
            <div class="ml mr text-justify text-muted" v-if="tieneAlias('Nota') && cliente.nota">
              <i class="mdi mdi-note-outline align-middle font-size-20 align-middle pe-2 text-primary"></i>
              <strong>{{cargarAliasCampo('Nota')}}</strong>
              <textarea
                class="form-control border-0 resize-none"
                readonly
                v-model="cliente.nota"
                rows="9"
              ></textarea>
            </div>

            <div class="ml mr mb-4 text-left">
              <p class="text-muted mb-0 mt-2" v-show="cliente.id != 901 && cliente.id != 902">
                <i class="mdi mdi-book-account-outline font-size-20 align-middle pe-2 text-primary"></i>
                <strong>Contacto: </strong>
                <button class="btn btn-success bg-gradient btn-sm"
                  @click="mostrarMdlWhatsapp(cliente.telefonos, nombreCliente())">
                  <i class="mdi mdi-whatsapp"></i>
                  Whatsapp
                </button>
                <button
                  class="btn btn-light btn-sm btn-outline-secondary bg-gradient"
                  name="vcard"
                  title="vCard del cliente"
                  @click="descargarVCard(cliente.id)"
                >
                <i class="mdi mdi-newspaper-variant-outline"></i>
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mdl-whatsapp ref="mdlWhatsapp"/>
  </div>
</template>

<script>
import ClienteSrv from '@/services/ClienteSrv.js'
import MdlWhatsapp from './MdlWhatsapp.vue'
import clienteIcon from '@/assets/img/usuario.png'
import SistemaSrv from '@/services/SistemaSrv.js'

import VueEasyLightbox from "vue-easy-lightbox"
import Swal from 'sweetalert2'

import API from '@/API.js'

export default {
  name: 'CmpInformacionCliente',
  components: { 
    MdlWhatsapp,
    VueEasyLightbox,
  },
  props: {
    idCliente: {
      type: Number,
      required: true
    },
    cargarAliasCampo:{
      type:Function,
      required: true
    },
    tieneAlias: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      tk: localStorage.getItem('argusblack.token'),
      API: API,
      clienteIcon: clienteIcon,
      foto_visor: null,
      foto: null,
      baseUrl: window.location.origin,
      claveVisible: false,
      cliente: {
        id: null,
        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        clave: null,
        prefnumcli: '',
        telefonos: [],
        messenger: '',
        url1: '',
        url2: '',
        datoaux1: '',
        datoaux2: '',
        datoaux3: '',
        datoaux4: '',
        tiene_foto: 0,
        activo: 1
      },
      foto_cliente: false,
      visibleImagen: false,
    }
  },
  created:function() {
    this.cargarFotoCliente()
  },
  mounted: function() {
    this.cargarCliente()
  },
  methods: {
    atributoVacio: function(atributo) {
      if (atributo == '' || atributo == null || atributo == undefined)
        return true
      return false
    },

    cadenaTelefonosCliente: function() {
      var cliente = this.cliente
      var cadena = ''

      cliente.telefonos.forEach(function(telefono, index) {
        cadena += telefono.numero

        if (index + 1 < cliente.telefonos.length) {
          cadena += ', '
        }
      })

      return cadena
    },

    cargarCliente: function() {
      var cmp = this

      ClienteSrv.clienteJSON(cmp.idCliente).then(response => {
        let cliente = response.data
        if (cliente.correos == undefined) cliente.correos = []
        if (cliente.telefonos == undefined) cliente.telefonos = []

        if(cliente.tiene_foto != false) {
          // cmp.foto = cmp.API + '/clientes/' + cliente.id + '/foto?foto=256x256&_tk=' + cmp.tk + '&rnd=' + Math.random()
          // cmp.foto_visor = cmp.API + '/clientes/' + cliente.id + '/foto?foto=original&_tk=' + cmp.tk + '&rnd=' + Math.random()
          cmp.foto = cmp.API + '/clientes/' + cliente.id + '/foto?foto=256x256&_tk=' + cmp.tk
          cmp.foto_visor = cmp.API + '/clientes/' + cliente.id + '/foto?foto=original&_tk=' + cmp.tk
        }

        cmp.cliente = cliente

        // Emite un evento "cliente-cargado" para notificar a la instancia Padre que el cliente ya
        // está cargado y de igual forma envía al "cliente cargado" a la instancia padre
        cmp.$emit('cliente-cargado', cmp.cliente)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el cliente'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    
    cargarFotoCliente: function() {
      var self = this

      SistemaSrv.ajustes(['foto_cliente']).then(response => {
        self.foto_cliente = response.data.foto_cliente == "0" ? false : true
      })
    },

    descargarVCard: function(idCliente) {
      window.open(`${API}/clientes/${idCliente}.vcf?_tk=${this.tk}`,'_blank')
    },

    editarCliente: function() {
      this.$router.push({
        name: 'edicionCliente',
        params: { id_cliente: this.idCliente }
      })
    },

    nombreCliente: function() {
      var cliente = this.cliente
      return (
        cliente.nombre +
        ' ' +
        cliente.apellido_paterno +
        ' ' +
        cliente.apellido_materno
      )
    },

    mostrarMdlWhatsapp(telefonos, nombreCliente) {
      let self = this
      self.$refs.mdlWhatsapp.mostrar(telefonos, nombreCliente)
    },

    nombre_completo:function(nombre = '', aPaterno = '', aMaterno = '') {
      let nombre_completo = nombre + ' ' + aPaterno + ' ' +aMaterno
      return nombre_completo.toUpperCase()
    },

    preguntaMotivoRetiro: function(idCliente, nombre) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      });

      swal.fire({
        title: "Motivo de retiro",
        html: 'Escribe el motivo por el cual será retirado el cliente <strong>' + nombre + '</strong>',
        input: "textarea",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "cancelar!",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#556ee6",
        cancelButtonColor: "#f46a6a",
      }).then(text => {
        if (text.value) {
          let params = { motivo_retiro: text.value }

          ClienteSrv.retirar(idCliente, params).then(response => {
            swal.fire("Retirado!", "Se retiró correctamente al cliente", "success");
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (error) {
              mensaje = 'No se pudo retirar el cliente'
            }
            iu.msg.error(mensaje)
            console.log(error)
          }).finally(() => { self.cargarCliente() })
        } else if ( text.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    
    preguntaRetirarCliente: function(idCliente, nombre) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      });

      swal.fire({
        title: "Retiro de cliente",
        html: '¿Desea retirar al cliente <strong>' + nombre + '</strong>?',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.preguntaMotivoRetiro(idCliente, nombre)
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    preguntaRestaurarCliente: function(idCliente, nombre) {
      var self = this
      
      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      });

      swal.fire({
        title: "Restaurar cliente",
        html: '¿Desea restaurar al cliente <strong>' + nombre + '</strong>?',
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          ClienteSrv.restaurar(idCliente).then(response => {
            swal.fire("Restaurado!", "Se restauró correctamente al cliente", "success");
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (error) {
              mensaje = 'No se pudo restaurar el cliente'
            }
            iu.msg.error(mensaje)
            console.log(error)
          }).finally(() => { self.cargarCliente() })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    }
  }
}
</script>

<style scoped>
.avatar-xl {
  height: 10rem;
  width: 10rem;
}
.mt-6 {
  margin-top: 4rem !important;
}
.ml {
  margin-left: 3rem !important;
}
.mr {
  margin-right: 3rem !important;
}
</style>