<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex">
        <div class="flex-shrink-0 me-3">
          <div class="avatar align-items-center">
            <div class="avatar-title rounded bg-info bg-gradient">
              <eva-icon name="calendar-outline" class="fill-white font-size-20"></eva-icon>
            </div>
          </div>
        </div>
        <div class="flex-grow-1">
          <h5 class="text-muted mb-1">Ultimo pago</h5>
          <h4 class="mb-0">{{ formatoMoneda(ultimoPago.importe) }}</h4>
        </div>

        <div class="flex-shrink-0 align-self-end ms-2" v-if="ultimoPago.fecha != undefined">
          <span class="badge rounded-pill font-size-13 badge-soft-success">
            {{ formatoFecha(ultimoPago.fecha) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClienteSrv from '@/services/ClienteSrv.js'
import accounting from 'accounting/accounting.js'
import moment from 'moment'

export default {
  name: 'CmpUltimoPago',
  props: {
    idCliente: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      ultimoPago: {}
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created: function() {
    iu.bus.on('abonos-actualizados', this.refrescarUltimoPago)
  },
  mounted: function() {
    this.refrescarUltimoPago()
  },
  methods: {
    formatoMoneda: function (numero) {
      return accounting.formatMoney(numero, { symbol: this.monedaSistema.simbolo, miles: ",", decimal: "." }) + ' ' + this.monedaSistema.codigo
    },
    formatoFecha(fecha) {
      return moment(fecha, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },
    refrescarUltimoPago: function() {
      var cmp = this

      ClienteSrv.ultimoPagoJSON(cmp.idCliente).then(response => {
        cmp.ultimoPago = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo refrescar el último pago'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  },
}
</script>