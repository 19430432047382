<template>
  <div class="modal fade" ref="CmpMdlTicketSoporte" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-show="modo == 'nuevo'">
            Nuevo ticket de soporte
          </h5>
          <h5 class="modal-title" v-show="modo == 'edicion'">
            Edición del ticket {{ ticket.id }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cerrar()"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-3" v-show="modo == 'edicion'">
              <label>Fecha</label> <br />
              <input
                class="form-control"
                v-bind:value="formatoFecha(ticket.created_at)"
                readonly
              />
            </div>
            <div class="col-md-3" v-show="modo == 'edicion'">
              <label>Hora</label>
              <input
                class="form-control"
                v-bind:value="formatoHora(ticket.created_at)"
                readonly
              />
            </div>
            <div class="col-md-3">
              <label>Prioridad</label>
              <select
                class="form-select"
                v-model="ticket.prioridad"
                v-bind:disabled="cerrado == true"
              >
                <option value="3" selected>Normal</option>
                <option value="2" class="text-warning">Media</option>
                <option value="1" class="fw-semibold text-danger">URGENTE</option>
              </select>
            </div>
            <div class="col-md-3">
              <div v-show="modo == 'edicion'">
                <label>Estado</label>
                <select
                  class="form-select"
                  v-model="ticket.completado"
                  v-bind:disabled="cerrado == true"
                >
                  <option value="0">Activo</option>
                  <option value="1">Completado</option>
                </select>
              </div>
            </div>
          </div>
          <br />

          <div class="row">
            <div class="col-md-6">
              <label>Nota del cliente</label>
              <textarea
                class="form-control"
                name="nota_cliente"
                v-model="ticket.nota_cliente"
                v-bind:disabled="cerrado == true"
              ></textarea>
            </div>
            <div class="col-md-6">
              <label>Nota de soporte</label>
              <textarea
                class="form-control"
                name="nota_soporte"
                v-model="ticket.nota_soporte"
                v-bind:disabled="cerrado == true"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light btn-sm"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="cerrar()"
          >
            <span v-show="modo == 'nuevo'">Cancelar</span>
            <span v-show="modo == 'edicion'">Cerrar</span>
          </button>
          <button
            type="button"
            class="btn btn-success btn-sm"
            v-show="modo == 'edicion' && cerrado == false"
            v-on:click="actualizar()"
          >
            <i class="mdi mdi-content-save-outline"></i>
            Actualizar
          </button>
          <button
            type="button"
            class="btn btn-success btn-sm"
            v-show="modo == 'nuevo'"
            v-on:click="guardar()"
          >
            <i class="mdi mdi-content-save-outline"></i>
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketSoportSrv from '@/services/TicketSoporteSrv.js'
import Modal from 'bootstrap/js/dist/modal';
import moment from 'moment'

export default {
  name: 'CmpMdlTicketSoporte',
  props: {
    idCliente: {
      type: Number,
      required: true
    },
    nombreCompletoCliente: {
      type: String,
      required: true
    },
    telefonosCliente: {
      type: Array,
      default (){
        return []
      } 
    }
  },
  data() {
    return {
      cerrado: false,
      modo: 'nuevo',
      ticket: {
        id: null,
        prioridad: 3,
        completado: 0,
        nota_cliente: '',
        nota_soporte: ''
      }
    }
  },

  created: function() {
    // Control de eventos
    iu.bus.on('mostrar-mdl-ticket-soporte', this.mostrarMdlTicketSoporte)
    iu.bus.on('abrir-ticket-soporte', this.abrirTicket)
  },

  methods: {
    abrirTicket: function(idTicket) {
      var self = this

      TicketSoportSrv.ticketJSON(idTicket).then(response => {
        let ticket = response.data
        self.ticket = ticket
        self.modo = 'edicion'

        if (self.ticket.completado == true) self.cerrado = true
        else self.cerrado = false

        // Apertura del modal
        var modal = new Modal(self.$refs.CmpMdlTicketSoporte)
        modal.show()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo abrir el ticket ' + idTicket
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    actualizar: function() {
      var self = this
      let ticket = Object.assign({}, self.ticket)

      TicketSoportSrv.actualizar(ticket).then(response => {
        if (self.ticket.completado == true) self.cerrado = true
        else self.cerrado = false

        iu.msg.success('Se actualizó correctamente')
        iu.bus.emit('ticket-soporte-actualizado')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar el ticket '
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cerrar: function() {
      var self = this

      self.limpiar()
      var modal = Modal.getInstance(self.$refs.CmpMdlTicketSoporte)
      modal.hide()
    },
    formatoFecha(fecha) {
      return moment(fecha, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },
    formatoHora(fecha) {
      return moment(fecha, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')
    },
    guardar: function() {
      var self = this

      var telefonos = []

      self.telefonosCliente.forEach(telefono => {
        telefonos.push({
          numero: telefono.numero,
          prefijo: telefono.prefijo,
          tipo: telefono.tipo
        })
      })

      var nuevoTicket = {
        id_cliente: self.idCliente,
        nombre: self.nombreCompletoCliente,
        prioridad: self.ticket.prioridad,
        completado: self.ticket.completado,
        telefonos: telefonos,
        nota_cliente: self.ticket.nota_cliente,
        nota_soporte: self.ticket.nota_soporte
      }

      TicketSoportSrv.guardar(nuevoTicket).then(response => {
        let id = response.data
        self.ticket = nuevoTicket
        self.ticket.id = id
        iu.msg.success('Se guardó correctamente')
        self.modo = 'edicion'
        self.refrescarTicket()
        iu.bus.emit('nuevo-ticket-soporte-guardado')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar el ticket '
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    limpiar: function() {
      var self = this

      self.modo = 'nuevo'
      self.ticket = {
        id: null,
        fecha: '',
        hora: '',
        prioridad: 3,
        completado: 0,
        nota_cliente: '',
        nota_soporte: ''
      }
    },
    mostrarMdlTicketSoporte: function(modo) {
      var self = this
      if (modo == 'nuevo') self.cerrado = false
      self.modo = modo

      // Apertura del modal
      var modalNuevo = new Modal(self.$refs.CmpMdlTicketSoporte)
      modalNuevo.show()
    },
    refrescarTicket: function() {
      var self = this

      TicketSoportSrv.ticketJSON(self.ticket.id).then(response => {
        self.ticket = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo refrescar el ticket '
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  },
}
</script>

<style scoped>
textarea {
  height: 200px;
}
</style>