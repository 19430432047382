<template>
  <div class="bx-pull-right">
    <button
      class="btn btn-success bg-gradient btn-sm"
      v-show="panelActivo == 'reportes'"
      v-on:click="mostrarMdlTicketSoporte('nuevo')"
    >
      <i class="fas fa-plus"></i>
      Nuevo
    </button>
  </div>
</template>

<script>
export default {
  name: 'CmpOpcionesReportes',
  props: ['panelActivo', 'idCliente', 'nombreCliente', 'telefonosCliente'],
  created: function() {},
  methods: {
    mostrarMdlTicketSoporte: function() {
      //           Evento                        Modo
      iu.bus.emit('mostrar-mdl-ticket-soporte', 'nuevo')
    }
  }
}
</script>

<style scoped>

</style>