<template>
  <div>
    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive">
          <table id="tblPanelReportes" class="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th colspan="2">
                  Creación de reporte
                </th>
                <th colspan="2" class="text-center">
                  Cierre de reporte
                </th>
              </tr>
              <tr>
                <th>ID</th>
                <th>Fecha</th>
                <th>Hora</th>
                <th>Fecha</th>
                <th>Hora</th>
                <th>Prioridad</th>
                <th>Estado</th>
                <th style="width: 800px">Nota de cliente</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="reporte in tickets" :key="reporte.id">
                <td class="fw-semibold cursor" @click="abrirTicketSoporte(reporte.id)">
                  {{ reporte.id }}
                </td>
                <td class="cursor" @click="abrirTicketSoporte(reporte.id)">
                  {{ formatoFecha(reporte.created_at) }}
                </td>
                <td class="cursor" @click="abrirTicketSoporte(reporte.id)">
                  {{ formatoHora(reporte.created_at) }}
                </td>
                <td class="cursor" @click="abrirTicketSoporte(reporte.id)">
                  <span
                    v-show="
                      reporte.mt_cierre != null &&
                      reporte.mt_cierre != '0000-00-00 00:00:00'
                    "
                  >
                    {{ formatoFecha(reporte.mt_cierre) }}
                  </span>
                </td>
                <td class="cursor" @click="abrirTicketSoporte(reporte.id)">
                  <span
                    v-show="
                      reporte.mt_cierre != null &&
                      reporte.mt_cierre != '0000-00-00 00:00:00'
                    "
                  >
                    {{ formatoHora(reporte.mt_cierre) }}
                  </span>
                </td>
                <td class="cursor" @click="abrirTicketSoporte(reporte.id)">
                  <span
                    v-show="reporte.prioridad == 1 && !reporte.completado"
                    class="badge font-size-13 badge-pill badge-soft-danger"
                  >
                    URGENTE
                  </span>
                  <span
                    v-show="reporte.prioridad == 2 && !reporte.completado"
                    class="badge font-size-13 badge-pill badge-soft-warning"
                  >
                    Media
                  </span>
                  <span
                    v-show="reporte.prioridad == 3 && !reporte.completado"
                    class="badge bg-light bg-gradient font-size-13"
                  >
                    Normal
                  </span>
                </td>
                <td class="cursor" @click="abrirTicketSoporte(reporte.id)">
                  <span class="badge bg-warning bg-gradient font-size-13" v-show="!reporte.completado">
                    Activo
                  </span>
                  <span class="badge bg-primary bg-gradient font-size-13" v-show="reporte.completado">
                    Completado
                  </span>
                </td>
                <td class="cursor" @click="abrirTicketSoporte(reporte.id)">
                  {{ reporte.nota_cliente }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center">
      <paginador
        :pag="pagina"
        :totpag="ultima_pagina"
        :epp="por_pagina"
        v-on:cargar-pagina="cargarPagina"
        v-on:epp-actualizado="
          por_pagina = $event,
          refrescarTickets()
        "
      ></paginador>
    </div>
  </div>
</template>

<script>
/**
 * Componente PANEL REPORTES
 */
import Paginador from '@/components/Paginador.vue'
import ClienteSrv from '@/services/ClienteSrv.js'
import moment from 'moment'

export default {
  name: 'CmpPanelReportes',
  props: {
    idCliente: {
      type: Number,
      required: true
    }
  },
  components: { Paginador },
  data() {
    return {
      tickets: [],
      pagina: 1,
      por_pagina: parseInt(localStorage.getItem('argusblack.cmpPanelReportes.por_pagina') || 10),
      todosSeleccionados: false,
      total: 0,
      ultima_pagina: 0
    }
  },

  created: function() {
    // Control de eventos externos

    this.refrescarTickets()

    iu.bus.on('nuevo-ticket-soporte-guardado', this.refrescarTickets)
    iu.bus.on('ticket-soporte-actualizado', this.refrescarTickets)

    // Si los tickets han sufrido algún cambio, entonces se manda a cargar los tickets nuevamente
    iu.bus.on('tickets-actualizados', this.refrescarTickets)
  },

  methods: {
    abrirTicketSoporte: function(idTicket) {
      iu.bus.emit('abrir-ticket-soporte', idTicket)
    },

    refrescarTickets: function() {
      var cmp = this

      iu.spinner.mostrar('#tblPanelReportes')
      
      let params = {
        pagina: cmp.pagina,
        por_pagina: parseInt(cmp.por_pagina),
        orden: 'desc'
      }

      ClienteSrv.ticketsSoporte(cmp.idCliente, params).then(response => {
        localStorage.setItem('argusblack.cmpPanelReportes.por_pagina', cmp.por_pagina)
        let res = response.data
        cmp.tickets = res.tickets
        cmp.total = res.total
        cmp.ultima_pagina = res.ultima_pagina
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los reportes del cliente'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tblPanelReportes')
      })
    },
    cargarPagina: function(n) {
      this.pagina = n
      this.refrescarTickets()
    },
    formatoFecha(fecha) {
      return moment(fecha, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },
    formatoHora(fecha) {
      return moment(fecha, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')
    },
    palancaSeleccionTodos: function() {
      var self = this
      if (
        self.ticketsSeleccionados.length > 0 &&
        self.ticketsSeleccionados.length < self.tickets.length
      ) {
        self.ticketsSeleccionados = []
        self.seleccionarTodosTickets()

        self.todosSeleccionados = true
      } else if (self.ticketsSeleccionados.length == 0) {
        self.seleccionarTodosTickets()
        self.todosSeleccionados = true
      } else {
        self.ticketsSeleccionados = []
        self.todosSeleccionados = false
      }

      self.ticketsSeleccionadosActualizados()
    },
    seleccionarTodosTickets: function() {
      var self = this
      self.tickets.forEach(function(ticket, index) {
        self.ticketsSeleccionados.push(ticket.id)
      })
    },
    ticketsSeleccionadosActualizados: function() {
      var self = this

      // Desactiva el checkbox de selección de todos los tickets si la cantidad de tickets seleccionados es
      // menor que la cantidad de tickets
      if (
        self.todosSeleccionados &&
        self.ticketsSeleccionados.length < self.tickets.length
      ) {
        self.todosSeleccionados = false
      }

      iu.bus.emit( 'tickets-seleccionados-actualizados', this.ticketsSeleccionados )
    }
  },
  mounted() {
    iu.spinner.mostrar('#tblPanelReportes')
  },
}
</script>

<style scoped>

</style>