<template>
  <div>
    <div class="row">
      <div class="col-sm-3">
        <div class="card">
          <div class="card-body">
            Gran total abonado: {{ hrr.fmon(totalAbonosCliente, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-6 mt-2-5 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarAbonos()
          "
          :datoini="dato"
          placeholder="Dato del pago"
        ></filtrador>
      </div>
    </div>

    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive">
          <table id="tblPanelPagos" class="table table-hover align-middle table-nowrap">
            <thead>
              <tr>
                <th>ID</th>
                <th>Forma de pago</th>
                <th>Banco</th>
                <th>Fecha</th>
                <th>Importe</th>
                <th>Nota</th>
                <th>Facturas abonadas</th>
                <th style="width: 120px">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="abono in abonos" :key="abono.id">
                <td class="fw-semibold">{{ abono.id }}</td>
                <td>
                  <span v-show="abono.metodo_pago.id == undefined" class="badge font-size-13 badge-pill badge-soft-success">
                    Efectivo
                  </span>
                  <span 
                    v-show="abono.metodo_pago.nombre != undefined" 
                    class="badge font-size-13 badge-pill"
                    :class="{
                      'badge-soft-success': abono.metodo_pago.nombre == 'Efectivo',
                      'badge-soft-primary': abono.metodo_pago.nombre == 'Depósito',
                      'badge-soft-warning': abono.metodo_pago.nombre == 'Transferencia',
                      'badge-soft-info': abono.metodo_pago.nombre == 'PayPal',
                      'badge-soft-dark': abono.metodo_pago.nombre == 'Mercado Pago'
                    }"
                  >
                    {{ abono.metodo_pago.nombre }}
                  </span>
                </td>
                <td>
                  <span v-if="abono.banco != null">
                    {{ abono.banco.nombre }}
                  </span>
                </td>
                <td>{{ abono.fecha }}</td>
                <td class="text-right">
                  {{ hrr.fmon(abono.importe, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                  <template v-if="abono.importe != abono.importe_asignado">
                    <br>
                    <span class="text-muted font-size-10">
                      Asignado: {{ hrr.fmon(abono.importe_asignado, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                    </span>
                    <br>
                    <span class="text-info font-size-10">
                      Disponible: {{ hrr.fmon(abono.importe - abono.importe_asignado, { symbol: monedaSistema.simbolo, miles: ",", decimal: "." }, monedaSistema.codigo) }}
                    </span>
                  </template>
                </td>
                <td>{{ abono.nota }}</td>
                <td>
                  <router-link
                    :to="{ name: 'factura', params: { id: factura.id } }"
                    v-for="(factura, index) in abono.facturas"
                    :key="index"
                    class="me-1"
                  >
                    {{ factura.ns }}
                    <br v-if="((index+1) % 4) == 0">
                  </router-link>
                </td>
                <td>
                  <button class="btn btn-soft-dark btn-sm" @click="imprimirTicketPago(abono)">
                    <i class="mdi mdi-printer"></i>
                    Imprimir
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="text-center">
      <paginador
        :pag="pagina"
        :totpag="ultima_pagina"
        :epp="por_pagina"
        v-on:cargar-pagina="cargarPagina"
        v-on:epp-actualizado="
          por_pagina = $event,
          refrescarAbonos()
        "
      ></paginador>
    </div>
  </div>
</template>

<script>
import API from '@/API.js'
import Paginador from '@/components/Paginador.vue'
import Filtrador from '@/components/Filtrador.vue'
import ClienteSrv from '@/services/ClienteSrv.js'
import moment from 'moment'
import md5 from 'md5'

export default {
  name: 'CmpPanelPagos',
  components: { Paginador, Filtrador },
  props: {
    idCliente: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      API: API,
      token: localStorage.getItem('argusblack.token'),
      hrr: iu.hrr,
      abonos: [],
      dato: '',
      pagina: 1,
      totalAbonosCliente: 0,
      por_pagina: parseInt(localStorage.getItem('argusblack.cmpPanelPagos.por_pagina') || 10),
      todosSeleccionados: false,
      total: 0,
      ultima_pagina: 0
    }
  },
  computed: {
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    }
  },
  created: function() {
    // Si los abonos han sufrido algún cambio, entonces se manda a cargar los abonos
    iu.bus.on('abonos-actualizados', this.refrescarAbonos)

    this.cargarTotalAbonos()
  },

  mounted() {
    iu.spinner.mostrar('#tblPanelPagos')
    this.refrescarAbonos()
  },

  methods: {
    refrescarAbonos: function() {
      var cmp = this

      iu.spinner.mostrar('#tblPanelPagos')

      let params = {
        dato: cmp.dato,
        pagina: cmp.pagina,
        por_pagina: parseInt(cmp.por_pagina),
        orden: 'desc',
        con_facturas_abonadas: true
      }

      ClienteSrv.abonosJSON(cmp.idCliente, params).then(response => {
        localStorage.setItem('argusblack.cmpPanelPagos.por_pagina', cmp.por_pagina)
        let res = response.data
        cmp.abonos = res.abonos
        cmp.total = res.total
        cmp.ultima_pagina = res.ultima_pagina

        // Deselección de abonos
        cmp.abonosSeleccionados = []
        cmp.todosSeleccionados = false
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los pagos del cliente'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tblPanelPagos')
      })
    },
    cargarPagina: function(n) {
      this.pagina = n
      this.refrescarAbonos()
    },
    cargarTotalAbonos: function() {
      var self = this

      ClienteSrv.totalAbonos(self.idCliente).then(response => {
        self.totalAbonosCliente = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    },

    imprimirTicketPago(abono) {
      let url = API+"/abonos/"+abono.id+"/ticket/"+md5(abono.id.toString())
      window.open(url,'_blank')
    }
  }
}
</script>

<style scoped>
.mt-2-5 {
  margin-top: 0.8rem !important;
}
</style>