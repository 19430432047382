<template>
  <ul class="nav nav-pills nav-justified">
    <li class="nav-item waves-effect waves-light">
      <a
        class="nav-link"
        :class="{ 
          active: panel == 'informacion',
          cursor: panel != 'informacion'
        }"
        data-bs-toggle="tab"
        @click="seleccionarPanel('informacion')"
      >
        <span class="d-block d-sm-none">
          <i class="fas fa-info-circle"></i>
        </span>
        <span class="d-none d-sm-block">
          Información
        </span>
      </a>
    </li>
    <li class="nav-item waves-effect waves-light">
      <a
        class="nav-link"
        :class="{ 
          active: panel == 'facturas',
          cursor: panel != 'facturas'
        }"
        data-bs-toggle="tab"
        @click="seleccionarPanel('facturas')"
        v-if="tienePermiso('ventas.facturasClienteJson','accion')"
      >
        <span class="d-block d-sm-none">
          <i class="fas fa-file-alt"></i>
        </span>
        <span class="d-none d-sm-block">
          Facturas
        </span>
      </a>
    </li>
    <li class="nav-item waves-effect waves-light">
      <a
        class="nav-link"
        :class="{
          active: panel == 'pagos',
          cursor: panel != 'pagos'
        }"
        data-bs-toggle="tab"
        @click="seleccionarPanel('pagos')"
        v-if="tienePermiso('cobranza.totalAbonosCliente','accion')"
      >
        <span class="d-block d-sm-none">
          <i class="fas fa-money-bill-wave"></i>
        </span>
        <span class="d-none d-sm-block">
          Pagos
        </span>
      </a>
    </li>
    <li class="nav-item waves-effect waves-light">
      <a 
        class="nav-link"
        :class="{
          active: panel == 'reportes',
          cursor: panel != 'reportes'
        }"
        data-bs-toggle="tab"
        @click="seleccionarPanel('reportes')"
        v-show="idCliente != 901 && idCliente != 902"
      >
        <span class="d-block d-sm-none">
          <i class="fas fa-book"></i>
        </span>
        <span class="d-none d-sm-block">
          Reportes
        </span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'BarraPaneles',
  props: {
    idCliente: {
      type: Number,
      required: true
    },
    tienePermiso: {
      type: Function,
      requerid: true
    }
  },
  data() {
    return {
      panel: 'informacion'
    }
  },

  created: function() {
    // Obtención del nombre del panel
    if (this.$route.params.nombrePanel != undefined) {
      this.seleccionarPanel(this.$route.params.nombrePanel)
    }
  },
  methods: {
    seleccionarPanel: function(nombrePanel) {
      this.panel = nombrePanel != '' ? nombrePanel : 'informacion'
      this.$router.replace({ params: { nombrePanel: nombrePanel } }).catch(error => {})
      
      this.$emit('panel-seleccionado', nombrePanel)
    }
  }
}
</script>

<style scoped>

</style>